import { httpStatusCode } from '~/core/enums/httpStatusCode'

export const globalErrors = {
  [httpStatusCode.Unauthorized]: {
    code: httpStatusCode.Unauthorized,
    title: 'Требуется регистрация',
    text: () => 'Попробуйте обновить страницу',
    img: '/graphics/401.svg',
    width: 232,
    height: 209,
    buttonColor: 'primary',
    buttonText: 'Зарегистрироваться'
  },
  [httpStatusCode.Forbidden]: {
    code: httpStatusCode.Forbidden,
    title: 'Нет доступа',
    text: () => 'К сожалению, у вас нет доступа к данной странице.',
    img: '/graphics/403.svg',
    width: 113,
    height: 150,
    buttonColor: 'primary',
    buttonText: 'На главную'
  },
  [httpStatusCode.NotFound]: {
    code: httpStatusCode.NotFound,
    title: 'Страница не найдена',
    text: () => 'К сожалению, мы не можем найти данную страницу.',
    img: '/graphics/404.svg',
    width: 174,
    height: 214,
    buttonColor: 'primary',
    buttonText: 'На главную'
  },
  [httpStatusCode.InternalServerError]: {
    code: httpStatusCode.InternalServerError,
    title: 'Что-то пошло не так',
    text: () => 'Попробуйте немного подождать и\u00A0обновить страницу',
    img: '/graphics/500.svg',
    width: 235,
    height: 228,
    buttonColor: 'primary',
    buttonText: 'На главную'
  }
} as const
