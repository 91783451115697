export const MEDCARD_MUTATIONS = {
  SET_STATUS: 'telemed/medcard/SET_STATUS',
  SET_MASTER_TOKEN: 'telemed/medcard/SET_MASTER_TOKEN',
  SET_ACCESS_TOKEN: 'telemed/medcard/SET_ACCESS_TOKEN'
}

export const MEDCARD_ACTIONS = {
  FETCH_MEDCARD_STATUS: 'telemed/medcard/FETCH_MEDCARD_STATUS',
  FETCH_MASTER_TOKEN: 'telemed/medcard/FETCH_MASTER_TOKEN',
  SETUP_MEDCARD_INFO: 'telemed/medcard/SETUP_MEDCARD_INFO'
}
