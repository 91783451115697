import type { ActionTree, MutationTree } from 'vuex'
import { MedcardResponseStatus, MedcardStatus } from '~/core/enums/medcardStatus'
import { medcardApi } from '~/core/api/medcardApi'
import { MEDCARD_TOKEN_KEY } from '~/core/constants/sessionStorageKey'
import { removeNamespace } from '~/core/functions/store'
import { MEDCARD_ACTIONS, MEDCARD_MUTATIONS } from '~/core/constants/store'

export const state = () => ({
  masterToken: '',
  medcardStatus: MedcardStatus.available as MedcardStatus,
  accessToken: sessionStorage.getItem(MEDCARD_TOKEN_KEY) || ''
})

export type RootState = ReturnType<typeof state>
const MUTATIONS_TYPES = removeNamespace('telemed/medcard/', MEDCARD_MUTATIONS)
const ACTIONS_TYPES = removeNamespace('telemed/medcard/', MEDCARD_ACTIONS)

export const mutations: MutationTree<RootState> = {
  [MUTATIONS_TYPES.SET_STATUS](state, payload: { status: MedcardStatus }) {
    state.medcardStatus = payload.status
  },
  [MUTATIONS_TYPES.SET_MASTER_TOKEN](state, payload: { token: string}) {
    state.masterToken = payload.token
  },
  [MUTATIONS_TYPES.SET_ACCESS_TOKEN](state, payload: { token: string }) {
    sessionStorage.setItem(MEDCARD_TOKEN_KEY, payload.token)
    state.accessToken = payload.token
  }
}

export const actions: ActionTree<RootState, any> = {
  async [ACTIONS_TYPES.FETCH_MEDCARD_STATUS]({ commit, state }) {
    try {
      await medcardApi.checkPermissions()

      commit(MUTATIONS_TYPES.SET_STATUS, { status: MedcardStatus.available })
    } catch (e) {
      switch (e?.response?.data?.detail) {
        case MedcardResponseStatus.protected:
          if (state.accessToken) {
            commit(MUTATIONS_TYPES.SET_STATUS, { status: MedcardStatus.available })
            break
          }

          commit(MUTATIONS_TYPES.SET_STATUS, { status: MedcardStatus.needEmailReconfirmation })
          break

        case MedcardResponseStatus.needConfirmation:
          commit(MUTATIONS_TYPES.SET_STATUS, { status: MedcardStatus.needEmail })
          break
        case MedcardResponseStatus.expired:
          commit(MUTATIONS_TYPES.SET_STATUS, { status: MedcardStatus.expired })
          break

        default:
          throw new Error(e)
      }
    }
  },
  async [ACTIONS_TYPES.FETCH_MASTER_TOKEN]({ state, commit }) {
    if (state.masterToken) {
      return
    }

    const { data: { master_medcard_token: token } } = await medcardApi.getMasterToken()
    commit(MUTATIONS_TYPES.SET_MASTER_TOKEN, { token })
  },
  async [ACTIONS_TYPES.SETUP_MEDCARD_INFO]({ dispatch, state }) {
    await dispatch(ACTIONS_TYPES.FETCH_MEDCARD_STATUS)

    if ([MedcardStatus.expired, MedcardStatus.available].includes(state.medcardStatus)) {
      return
    }

    await dispatch(ACTIONS_TYPES.FETCH_MASTER_TOKEN)
  }
}
