import { Context, Plugin } from '@nuxt/types'

const realVh: Plugin = ({ $device }: Context) => {
  if ($device.isMobile) {
    document.documentElement.classList.add('is-mobile')
  }

  setRealVH()
  window.addEventListener('resize', setRealVH)

  function setRealVH() {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }
}

export default realVh
