import { mtClient } from '~/core/clients/mtClient'

const RESOURCE = '/medcard'

export const medcardApi = {
  checkPermissions() {
    return mtClient.get<void>(`${RESOURCE}/check_permissions/`, {
      withCredentials: true
    })
  },
  getMasterToken() {
    return mtClient.post<{ master_medcard_token: string }>(`${RESOURCE}/token/`)
  }
}
