
import { NuxtError } from '@nuxt/types'
import { httpStatusCode } from '~/core/enums/httpStatusCode'
import { globalErrors } from '~/core/data/globalErrors'
import {
  computed,
  defineComponent,
  PropType
} from '~/bridge'

type TError = Omit<NuxtError, 'statusCode'> &
  { statusCode: httpStatusCode | undefined }

export default defineComponent({
  name: 'Error',
  layout: 'errorLayout',
  props: {
    error: { type: Object as PropType<TError>, default: () => ({}) }
  },
  setup(props) {
    const errorObj = computed(() => {
      const { statusCode: code } = props.error

      if (code) {
        return globalErrors[code.toString().startsWith('5') ? httpStatusCode.InternalServerError : code] ?? globalErrors[httpStatusCode.InternalServerError]
      }

      return globalErrors[httpStatusCode.InternalServerError]
    })

    return {
      errorObj
    }
  }
})
