import type { ActionTree, MutationTree } from 'vuex'
import Cookies from 'js-cookie'
import { addMinutes } from 'date-fns'
import { authorizationApi } from '~/core/api/authorizationApi'
import { httpStatusCode } from '~/core/enums/httpStatusCode'
import { MT_INFO_AUTH_TOKEN_KEY } from '~/core/constants/sessionStorageKey'
import { removeNamespace } from '~/core/functions/store'
import { USER_ACTIONS, USER_MUTATIONS } from '~/core/constants/store'
import { useRoute } from '~/bridge'

export const state = () => ({
  email: ''
})

export type RootState = ReturnType<typeof state>
const MUTATIONS_TYPES = removeNamespace('telemed/user/', USER_MUTATIONS)
const ACTIONS_TYPES = removeNamespace('telemed/user/', USER_ACTIONS)

export const mutations: MutationTree<RootState> = {
  [MUTATIONS_TYPES.SET_EMAIL](state, payload: { email: string }) {
    state.email = payload.email
  }
}

export const actions: ActionTree<RootState, any> = {
  async [ACTIONS_TYPES.FETCH_USER_INFO]({ commit }) {
    const { data: { email } } = await authorizationApi.getProfileFromInfo()
    commit(MUTATIONS_TYPES.SET_EMAIL, { email })
  },
  async [ACTIONS_TYPES.AUTHORIZE]({ dispatch }, payload: { token: string }) {
    const appointmentId = useRoute().params.id

    try {
      if (!sessionStorage.getItem(MT_INFO_AUTH_TOKEN_KEY)) {
        sessionStorage.setItem(MT_INFO_AUTH_TOKEN_KEY, Cookies.get(`${MT_INFO_AUTH_TOKEN_KEY}_${appointmentId}`) ?? '')
      }

      await dispatch(ACTIONS_TYPES.FETCH_USER_INFO)
    } catch (e) {
      if (e?.response.status !== httpStatusCode.Unauthorized) {
        throw new Error(e)
      }

      const { data: { profile_token } } = await authorizationApi.authByToken(payload.token)
      sessionStorage.setItem(MT_INFO_AUTH_TOKEN_KEY, profile_token)
      Cookies.set(`${MT_INFO_AUTH_TOKEN_KEY}_${appointmentId}`, profile_token, {
        expires: addMinutes(new Date(), 20)
      })

      await dispatch(ACTIONS_TYPES.FETCH_USER_INFO)
    }
  }
}
