export enum MedcardStatus {
  needEmail = 'need_email',
  needEmailReconfirmation = 'need_email_reconfirmation',
  available = 'available',
  expired = 'expired'
}

export enum MedcardResponseStatus {
  needConfirmation = 'need_confirmation',
  expired = 'expired',
  protected = 'protected'
}
