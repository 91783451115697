import axios from 'axios'
import { MT_INFO_AUTH_TOKEN_KEY } from '~/core/constants/sessionStorageKey'

export const mtInfoClient = axios.create({
  // NOTE: Запросы проксируются через NGINX
  baseURL: '/info/api',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

mtInfoClient.interceptors.request.use((config) => {
  config.headers!.Authorization = `Token ${sessionStorage.getItem(MT_INFO_AUTH_TOKEN_KEY)}`

  return config
})
