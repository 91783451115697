import { mtClient } from '~/core/clients/mtClient'
import { mtInfoClient } from '~/core/clients/mtInfoClient'

export const authorizationApi = {
  authByToken(token?: string) {
    return mtClient.post<{ profile_token: string }>(
      '/tokens/medtochka_info/profile_token_via_urltoken/',
      {},
      {
        params: {
          token
        },
        withCredentials: true
      }
    )
  },
  getProfileFromInfo() {
    return mtInfoClient.get<{ email: string }>('/profile/info/')
  },
  getUrlAuthToken() {
    return mtClient.post<{ url_auth_token: string }>('/tokens/url_auth_token/')
  }
}
