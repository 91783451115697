import axios from 'axios'
import Cookies from 'js-cookie'

const mtClient = axios.create({
  // NOTE: Запросы проксируются через NGINX
  baseURL: '/master/api',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

mtClient.interceptors.request.use((config) => {
  config.headers!['X-CSRFToken'] = Cookies.get('csrftoken') || ''

  return config
})

export { mtClient }
