import { Context } from '@nuxt/types'
import { isObject } from 'lodash-es'
import { CORE_MUTATIONS } from '~/core/constants/store'
import { featureFlagsApi } from '~/core/api/featureFlagsApi'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'

export default async function({ store, route, error, $sentry }: Context) {
  try {
    const { data: featureFlags } = await featureFlagsApi.get()
    const meta = route.meta?.[0] || {} // NOTE: Используется для ограничения доступа к роутам по флагу, передаваемому в meta

    if (isObject(featureFlags)) {
      store.commit(CORE_MUTATIONS.SET_FEATURE_FLAGS, featureFlags)
    }

    if (!isFeatureEnabled(meta.featureFlag, featureFlags)) {
      error({ statusCode: 403, message: 'Forbidden' })
    }
  } catch (error) {
    $sentry.captureException(error)
  }
}
