import maskit from '~/plugins/mask/functions/maskit'
import dynamicMask from '~/plugins/mask/functions/dynamic-mask'
import { TMaskTokens } from '~/plugins/mask/types/tokens'

// Facade to maskit/dynamicMask when mask is String or Array
const masker = (
  value: string,
  mask: Array<string> | string,
  masked = true,
  tokens: TMaskTokens
) => Array.isArray(mask)
  ? dynamicMask(maskit, mask, tokens)(value, mask, masked)
  : maskit(value, mask, masked, tokens)

export default masker
