import type { MutationTree } from 'vuex'
import { CORE_MUTATIONS } from '~/core/constants/store'

export const state = () => ({
  featureFlags: {} as Record<string, boolean>
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [CORE_MUTATIONS.SET_FEATURE_FLAGS](state, payload: Record<string, boolean>) {
    state.featureFlags = payload
  }
}
